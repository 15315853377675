<template>
	<div class="evaluation " style="margin-top: 20px;">
		<div class="u-f-item" style="padding-bottom: 15px;">
			<div style="width: 4px;height: 15px;background-color: #007AFF;margin-right: 10px;"></div>
			<div style="color: #5D5D5D;" class="font_blod font_16">2021学年度-上学期</div>
		</div>
		<div class="evaluation-list" v-for="(items,i) in evaluationList" :key="i">
			<div class="evaluation-item mouse">
				<div>评语：{{items.title}}</div>
				<div>德育评价：{{items.grade}}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			evaluationList:[
				{
					title:'--',
					grade:'--',
				},
			]
		};
	},
	created() {},
	mounted() {},
	methods: {
	}
};
</script>

<style lang="scss">
.evaluation  {
	background-color: #ffffff;
	height: 75vh;
	margin-top: 20px;
	padding: 15px 20px;
	overflow-y: scroll;
	.evaluation-list{
		margin-bottom: 20px;
		.evaluation-item{
			background-color: #F3F3F3;
			padding: 20px;
			color: #5D5D5D;
			div{
				line-height: 1.6;
			}
		}
		
	}
}
</style>
