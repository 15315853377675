<template>
	<div style="padding-top: 20px;">
		<div class="contents">
			<div class="head u-f-item">
				<div class="total">
					<div class="times">
						0次
					</div>
					<div class="desc">共0次，<span>学生请假0次, </span><span>新冠感染0次, </span><span>测试0次, </span><span>事假0次, </span><span>病假0次, </span><span>其他260次</span></div>
				</div>
				<div class="statictics u-f-item">
					<ve-ring height="180px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData1"></ve-ring>
					<ve-ring height="180px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData2"></ve-ring>
					<ve-ring height="180px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData3"></ve-ring>
					<ve-ring height="180px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData4"></ve-ring>
					<ve-ring height="180px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData5"></ve-ring>
					<ve-ring height="180px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData6"></ve-ring>
				</div>
			</div>
			<div class="table">
				<el-table ref="multipleTable" :data="groupList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="id" label="序号"></el-table-column>
					<el-table-column prop="year" label="学年"></el-table-column>
					<el-table-column prop="term" label="学期"></el-table-column>
					<el-table-column prop="class" label="日期" sortable></el-table-column>
					<el-table-column prop="course" label="时间"></el-table-column>
					<el-table-column prop="group" label="原因" :filters="[{ text: '家', value: '家' }, { text: '公司', value: '公司' }]"
					:filter-method="filterTag"
					filter-placement="bottom-end"></el-table-column>
					<el-table-column prop="student" label="备注"></el-table-column>
					<el-table-column prop="stair" label="图片"></el-table-column>
					<el-table-column prop="second" label="状态" :filters="[{ text: '家', value: '家' }, { text: '公司', value: '公司' }]"
					:filter-method="filterTag"
					filter-placement="bottom-end"></el-table-column>
					<el-table-column label="审核人"></el-table-column>
					<el-table-column prop="score" label="审核备注"></el-table-column>
					<el-table-column prop="explain" label="审核时间"></el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			multipleSelection: [],
			ringExtend:{
				legend: {
					show: true,
					icon:'circle'
				},
			},
			settings: {
				radius: [45, 60],
				offsetY: 80,
				labelLine: {
					show: false
				},
				label: {
					show: false
				}
			},
			appColor: ['#A190F7', '#FF7A65', '#14DAAF', '#2D8CF0'],
			chartData1: {
				columns: ['name', 'values'],
				rows: [{ name: '学生请假', values: 0 }]
			},
			chartData2: {
				columns: ['name', 'values'],
				rows: [{ name: '新冠感染', values: 0 }]
			},
			chartData3: {
				columns: ['name', 'values'],
				rows: [{ name: '测试', values: 0 }]
			},
			chartData4: {
				columns: ['name', 'values'],
				rows: [{ name: '事假', values: 0 }]
			},
			chartData5: {
				columns: ['name', 'values'],
				rows: [{ name: '病假', values: 0 }]
			},
			chartData6: {
				columns: ['name', 'values'],
				rows: [{ name: '其他', values: 0 }]
			},
			groupList:[]
		};
	},
	created() {},
	mounted() {},
	methods: {
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		filterTag(value, row) {
			return row.tag === value;
		},
	}
};
</script>

<style lang="scss">
.contents {
	background-color: #ffffff;
	padding: 20px;
	.total{
		width: 13%;
		margin-right: 2%;
		.times{
			font-size: 1.8rem;
			margin-bottom: .4rem;
			color: #666;
		}
		.desc{
			color: #777;
		}
	}
	.statictics{
		width: 75%;
		padding: 30px 0;
	}
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
}
</style>
