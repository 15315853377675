<template>
	<div id="information">
		<div class="u-f-item title">
			<div class="line"></div>
			<div class="font_16">监护人1</div>
		</div>
		<div class="infos">
			<div class="u-f-item">
				<div style="margin-right: 10px;">关系</div>
				<div>{{base_info.relationship1_text||'-'}}</div>
			</div>
			<div class="u-f-item">
				<div style="margin-right: 10px;">姓名</div>
				<div>{{base_info.name1||'-'}}</div>
			</div>
			<div class="u-f-item">
				<div style="margin-right: 10px;">电话</div>
				<div>{{base_info.tel1||'-'}}</div>
			</div>
			<div class="u-f-item">
				<div style="margin-right: 10px;">工作单位</div>
				<div>{{base_info.work_unit1||'-'}}</div>
			</div>
		</div>
		<div class="u-f-item title">
			<div class="line"></div>
			<div class="font_16">监护人2</div>
		</div>
		<div class="infos">
			<div class="u-f-item">
				<div style="margin-right: 10px;">关系</div>
				<div>{{base_info.relationship2_text||'-'}}</div>
			</div>
			<div class="u-f-item">
				<div style="margin-right: 10px;">姓名</div>
				<div>{{base_info.name2||'-'}}</div>
			</div>
			<div class="u-f-item">
				<div style="margin-right: 10px;">电话</div>
				<div>{{base_info.tel2||'-'}}</div>
			</div>
			<div class="u-f-item">
				<div style="margin-right: 10px;">工作单位</div>
				<div>{{base_info.work_unit2||'-'}}</div>
			</div>
		</div>
		<div class="u-f-item title">
			<div class="line"></div>
			<div class="font_16">家庭住址</div>
		</div>
		<div class="infos" style="padding-bottom: 20px;">
			<div>{{base_info.family_address||'-'}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			base_info: {
				type: Object,
				default: {}
			}
		},
		data() {
			return {};
		},
		watch: {
			base_info: {
				immediate: true,
				handler(value) {
					this.base_info = value;
				}
			}
		},
		methods: {
		}
	};
</script>

<style lang="scss">
	#information{
		background-color: #FFFFFF;
		height: 74vh;
		margin-top: 20px;
		padding: 20px;
		.title{
			.line{
				width: 0.2vw;
				height: 1.8vh;
				background-color: #007AFF;
				margin-right: 5px;
				border-radius: 20px;
			}
		}
		.infos{
			background-color: #f3f3f3;
			margin-top: 20px;
			padding: 20px 40px 0 40px;
			margin-bottom: 20px;
			color: #555555;
			div{
				margin-bottom: 15px;
			}
		}
	}
</style>
