<template>
	<div style="padding-top: 20px;" id="student_sche">
		<div class="contents">
			<div class="u-f-item head">
				<el-select v-model="week" placeholder="请选择" style="width: 120px;" @change="weekChange">
					<el-option  v-for="item in weekList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<!-- <div style="margin:0 30px;">显示和导出设置</div>
				<el-checkbox-group v-model="checkList">
					<el-checkbox label="教室"></el-checkbox>
					<el-checkbox label="教师"></el-checkbox>
				</el-checkbox-group> -->
			</div>
			<div class="u-f table" style="border-top: 1px solid #EEEEEE;">
				<div class="th-1 u-f-justify u-f-column"></div>
				<div class="th u-f-justify u-f-column" v-for="(item, index) in attend_day" :key="index">
					<div>{{item.name}}</div>
					<div style="margin-top: 10px;">{{item.date}}</div>
				</div>
			</div>
			<div class="u-f table" v-for="(item,index) in curriculum_time" :key="index">
				<div class="th-1 u-f-justify u-f-column">
					<div>{{item.node}}</div>
					<div>{{item.start}}~{{item.end}} </div>
				</div>
				<div class="th u-f-justify u-f-column" :class="val.class?'active':''" v-for="(val,i) in item.list" :key="i">
					<el-tooltip placement="bottom">
						<div slot="content">
							<div style="border-bottom: 1px dashed #fff;padding-bottom:10px;margin-bottom:10px">班级：{{val.class_name||''}}</div>
							<div>学科：{{val.discipline_name||''}}</div>
							<div>教师：{{val.teacher_name||''}}</div>
						</div>
						<div>
							<div>{{val.class_name||''}}</div>
							<div>{{val.discipline_name||''}}</div>
							<div>{{val.teacher_name||'-'}}</div>
						</div>
					</el-tooltip>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		base:{
			type:Object,
			default:{}
		}
	},
	data() {
		return {
			curriculum_time: [],
			attend_day: [],
			courseData: [],
			weekList:[],
			week:''
		};
	},
	created() {},
	mounted() {
		setTimeout(()=>{
			this.getDate()
		},500)
	},
	methods: {
		weekChange(e) {
			this.getStudentCourse();
		},
		// 获取当前时间
		getDate() {
			this.$api.setting
				.getweekList({
					semester_id: window.localStorage.getItem('semester_id')
				})
				.then(res => {
					if (res.data.code == 1) {
						let data = res.data.data.list;
						let arr = [];
						for (let i = 0;i < data.length;i++) {
							arr.push({
								name: data[i],
								id: i + 1
							});
						}
						this.weekList = arr;
						this.week = res.data.data.now;
						this.getStudentCourse()
					}
				});
		},
		getStudentCourse(){
			this.$api.student.student_course({
				week: this.week,
				class_id:this.base.class_id
			}).then(res => {
				if (res.data.code == 1) {
					this.attend_day = res.data.data.attend_day;
					let curriculum_time = res.data.data.curriculum_time;
					let list = [];
					if(res.data.data.data){
						list = res.data.data.data
					}
					for(let i=0;i<curriculum_time.length;i++){
						this.$set(curriculum_time[i], 'list', [])
						for(let j=0;j<this.attend_day.length;j++){
							curriculum_time[i].list.push({xq: this.attend_day[j].name, node: curriculum_time[i].node})
							let arr = list.filter(item=>item.attend_day == this.attend_day[j].name && item.node == curriculum_time[i].node);
							console.log(list)
							if(arr.length){
								curriculum_time[i].list[j].discipline_name = arr[0].discipline_name;
								curriculum_time[i].list[j].class_name = arr[0].class_name;
								curriculum_time[i].list[j].teacher_name = arr[0].teacher_name;
							}
						}
					}
					
					this.curriculum_time = curriculum_time;
					console.log(this.curriculum_time);
				} else {
					this.$message.error(res.data.msg);
				}
			});
		}
	}
};
</script>

<style lang="scss">
#student_sche{
	.contents {
		background-color: #ffffff;
		.head {
			padding: 5px 20px;
		}
		.table {
			.el-tooltip{
				width: 100%;
				height: 100%;
				padding: 10px 0;
			}
			.th-1 {
				width: 8%;
				text-align: center;
				height: 70px;
				border-right: 1px solid #EEEEEE;
				border-bottom: 1px solid #EEEEEE;
			}
			.th {
				width: 13.5%;
				text-align: center;
				height: 70px;
				border-right: 1px solid #EEEEEE;
				border-bottom: 1px solid #EEEEEE;
			}
			.th_h{
				background: #EEEEEE;
			}
			.th:hover{
				background-color: #EEFAF6;
				cursor: pointer;
			}
			.th_h:hover{
				background-color: #EEEEEE;
			}
		}
	}
}
</style>
