<template>
	<div class="archives" id="student">
		<el-row :gutter="20" style="margin-top: 1.04vw;">
			<el-col :md="24" :lg="6" :xl="6" style="margin-bottom: 1.04vw;">
				<div class="info">
					<div class="u-f-justify u-f-column">
						<el-avatar style="width: 3.56vw;height: 3.56vw;" :src="base_info.avatar_url||''"></el-avatar>
						<div class="font_14 m_t">
							{{base_info.realname}}
							<i class="iconfont " :class="base_info.sex==1?'icon-nan':'icon-nv'"></i>
						</div>
					</div>
					<div class="u-f-item classinfo u-f-jsb">
						<div class="item" style="background: #E2F1FF;">
							<div class="tag" style="background: #1890FF;">班级</div>
							<div class="icon"><img src="../../assets/image/icon1.png" alt=""></div>
							<div class="name">{{base_info.grade_num}}{{base_info.class_name}}</div>
						</div>
						<div class="item" style="background: #EEFAF6;">
							<div class="tag" style="background: #4CD6B8;">班主任</div>
							<div class="icon"><img src="../../assets/image/icon2.png" alt=""></div>
							<div class="name">{{base_info.class_teacher||'-'}}</div>
						</div>
						<div class="item" style="background: #FFE1DF;">
							<div class="tag" style="background: #FC8078;">身份</div>
							<div class="icon"><img src="../../assets/image/icon3.png" alt=""></div>
							<div class="name">
								<blockquote style="margin:0" v-if="base_info.identity==1">群众</blockquote>
								<blockquote style="margin:0" v-else-if="base_info.identity==2">少先队员</blockquote>
								<blockquote style="margin:0" v-else-if="base_info.identity==3">团员</blockquote>
								<blockquote style="margin:0" v-else-if="base_info.identity==4">党员</blockquote>
							</div>
						</div>
					</div>
					<div class="info-item u-f font_14 m_t_15" v-if="base_info.class_num">
						<div style="color: #6A6A6A;">学号：</div>
						{{base_info.class_num||'-'}}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">学籍号：</div>
						{{base_info.student_num||'-'}}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">是否住宿：</div>
						{{base_info.bed?'是':'否'}}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">身份证：</div>
						{{base_info.idcard||'-'}}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">出生日期：</div>
						{{base_info.birthday||'-'}}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">联系电话：</div>
						{{base_info.phone||'-'}}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">学籍性质：</div>
						{{base_info.stunature_status==1?'本校':'挂学籍'}}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">状态：</div>
						{{base_info.status==1?'在校':'离校'}}
					</div>
					<!-- <div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">身份：</div>
						
					</div> -->
					<div class="info-item u-f font_14 m_t_15" v-if="base_info.former_school">
						<div style="color: #6A6A6A;">原学校：</div>
						{{base_info.former_school||'-'}}
					</div>
				</div>
			</el-col>
			<el-col :md="24" :lg="12" :xl="12" style="margin-bottom: 1.04vw;">
				<div class="class_table" style="color: #535353;">
					<div class="u-f-item u-f-jsb p_15">
						<div class="font_16 font_blod" v-if="weekList.length">{{weekList[weeknum-1].name}}课表</div>
						<div class="mouse" style="color: #A0ACB4;" @click="more(2)">
							更多
							<i class="el-icon-caret-right"></i>
						</div>
					</div>
					<div class="tables" style="color: #555555;">
						<div class="th u-f">
							<div class="b-r b-t p-t-b_12" style="width: 15%;text-align: center;"></div>
							<div class="b-r b-t p-t-b_12 font_blod" style="width: 17%;text-align: center;" v-for="(item, index) in attend_day" :key="index">{{ item.name }}</div>
						</div>
						<div class="tb u-f" v-for="(item, index) in curriculum_time" :key="index">
							<div class="b-r b-t p-t-b_12" style="width: 15%;text-align: center;">{{ item.node }}</div>
							<div
								class="b-r b-t p-t-b_12 u-line-1"
								style="width: 17%;text-align: center;"
								v-for="(items, ind) in item.list"
								:key="ind"
								:class="items.discipline_name ? 'active' : ''"
							>
								{{ items.discipline_name || '' }}
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<!-- <el-col :md="24" :lg="6" :xl="6" style="margin-bottom: 1.04vw;">
				<div class="timecard" style="color: #535353;">
					<div class="u-f-item u-f-jsb b-t" style="height: 50px;line-height: 50px;padding: 0 15px;">
						<div class="font_16 font_blod">住宿生记录</div>
						<div class="mouse" style="color: #A0ACB4;">
							更多
							<i class="el-icon-caret-right"></i>
						</div>
					</div>
					<div style="padding-bottom: 15px">
						<ve-ring height="200px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData6"></ve-ring>
					</div>
				</div>
				<div class="station m_t_20" style="color: #535353;">
					<div class="u-f-item u-f-jsb b-t" style="height: 50px;line-height: 50px;padding: 0 15px;">
						<div class="font_16 font_blod">小组学习记录</div>
						<div style="color: #A0ACB4;" class="mouse" @click="more(2)">
							更多
							<i class="el-icon-caret-right"></i>
						</div>
					</div>
					<div style="padding-bottom: 15px">
						<ve-ring height="200px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData6"></ve-ring>
					</div>
				</div>
			</el-col> -->
			<el-col :md="24" :lg="6" :xl="6" style="margin-bottom: 1.04vw;">
				<div class="timecard" style="color: #535353;">
					<div class="u-f-item u-f-jsb b-t" style="height: 50px;line-height: 50px;padding: 0 15px;">
						<div class="font_16 font_blod">请假统计</div>
						<!-- <div class="mouse" style="color: #A0ACB4;">
							更多
							<i class="el-icon-caret-right"></i>
						</div> -->
					</div>
					<div class="timecard-list">
						<ve-ring height="200px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData4"></ve-ring>
					</div>
					<div style="height: 170px">
						<div class="timecard-label">
							<div class="lable-item u-f-item u-f-jsb" v-for="(item, index) in chartData4.rows" :key="index">
								<div>
									<i :style="{background: appColor[index]}"></i><span>{{item.name}}</span>
								</div>
								<div class="values">{{item.values}}</div>
							</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :md="24" :lg="8" :xl="8" style="margin-bottom: 1.04vw;">
				<div class="grade" style="color: #535353;">
					<div class="u-f-item u-f-jsb p_15 b-t">
						<div class="font_16 font_blod">考试成绩</div>
						<div style="color: #A0ACB4;" class="mouse">
							更多
							<i class="el-icon-caret-right"></i>
						</div>
					</div>
					<div style="padding: 0 15px 15px 15px;height:315px;box-sizing: border-box;">
						<div class="u-f">
							<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">学科</div>
							<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">分值</div>
							<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">年级排名</div>
							<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">
								班级排名
								<!-- <i class="el-icon-d-caret"></i> -->
							</div>
						</div>
						<div class="score">
							<div class="u-f score-item" v-for="(item, index) in recent_exam" :key="index">
								<div>{{item.discipline_name}}</div>
								<div>{{item.score}}</div>
								<div>{{item.grade_ranking}}</div>
								<div>{{item.class_ranking}}</div>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :md="24" :lg="8" :xl="8">
				<div class="timecard" style="color: #535353;">
					<div class="u-f-item u-f-jsb b-t" style="height: 50px;line-height: 50px;padding: 0 15px;">
						<div class="font_16 font_blod">奖惩记录</div>
						<!-- <div class="mouse" style="color: #A0ACB4;">
							更多
							<i class="el-icon-caret-right"></i>
						</div> -->
					</div>
					<div style="padding-bottom: 15px">
						<ve-ring height="300px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData5"></ve-ring>
					</div>
				</div>
			</el-col>
			<el-col :md="24" :lg="8" :xl="8">
				<div class="station" style="color: #535353;">
					<div class="u-f-item u-f-jsb b-t" style="height: 50px;line-height: 50px;padding: 0 15px;">
						<div class="font_16 font_blod">学生评价</div>
					</div>
					<div style="padding-bottom: 15px">
						<ve-ring height="300px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData6"></ve-ring>
					</div>
				</div>
			</el-col>
			<!-- <el-col :md="24" :lg="8" :xl="8" style="margin-bottom: 1.04vw;">
				<div class="timecard" style="color: #535353;">
					<div class="u-f-item u-f-jsb p_15 b-t">
						<div class="font_16 font_blod">材料证明</div>
						<div style="color: #A0ACB4;" class="mouse">
							更多
							<i class="el-icon-caret-right"></i>
						</div>
					</div>
					<div style="background: #fff;height:340px">
						<VeHistogram :data="chartData" :extend="chartExtend" style="height: 260px;width: 100%;"></VeHistogram>
					</div>
				</div>
			</el-col> -->
		</el-row>
		<!-- <el-row :gutter="20">
			<el-col :md="24" :lg="8" :xl="8" style="margin-bottom: 1.04vw;">
				<div class="grade" style="color: #535353;">
					<div class="u-f-item u-f-jsb p_15 b-t">
						<div class="font_16 font_blod">学分</div>
						<div style="color: #A0ACB4;" class="mouse">
							更多
							<i class="el-icon-caret-right"></i>
						</div>
					</div>
					<div style="padding: 0 15px 15px 15px;" class="u-f-item">
						<ve-ring height="200px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData2"></ve-ring>
						<ve-ring height="200px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData3"></ve-ring>
					</div>
				</div>
			</el-col>
			<el-col :md="24" :lg="8" :xl="8" style="margin-bottom: 1.04vw;">
				<div class="grade" style="color: #535353;">
					<div class="u-f-item u-f-jsb p_15 b-t">
						<div class="font_16 font_blod">过程性记录</div>
						<div style="color: #A0ACB4;" class="mouse">
							更多
							<i class="el-icon-caret-right"></i>
						</div>
					</div>
					<div style="padding: 0 15px 15px 15px;">
						<ve-ring height="200px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData5"></ve-ring>
					</div>
				</div>
			</el-col>
			<el-col :md="24" :lg="8" :xl="8" style="margin-bottom: 1.04vw;">
				<div class="grade" style="color: #535353;">
					<div class="u-f-item u-f-jsb p_15 b-t">
						<div class="font_16 font_blod">社团记录</div>
						<div style="color: #A0ACB4;" class="mouse">
							更多
							<i class="el-icon-caret-right"></i>
						</div>
					</div>
					<div style="padding: 0 15px 15px 15px;">
						<ve-ring height="200px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData5"></ve-ring>
					</div>
				</div>
			</el-col>
		</el-row> -->
	</div>
</template>

<script>
export default {
	props: {
		base_info: {
			type: Object,
			default: {}
		},
		recent_exam: {
			type: Array,
			default: []
		},
		base:{
			type: Object,
			default: {}
		},
		evaluation:{
			type: Array,
			default: []
		},
		levae:{
			type: Array,
			default: []
		},
		rewards:{
			type: Array,
			default: []
		}
	},
	data() {
		return {
			curriculum_time: [],
			attend_day: [],
			courseData: [],
			id: '',
			chartExtend: {
				legend: {
					show: false
				},
				grid:{
					height:240
				},
				xAxis: {
					type:'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLabel: {
						textStyle: {    //文字样式
							color: "#878787"
						}
					}
				},
				yAxis: {
					type:'value',
					axisLabel: {
						textStyle: {    //文字样式
							color: "#878787"
						}
					}
					
				},
				series: {
					type: 'bar',
					barWidth : 50,
					itemStyle: {
						normal: {
							color: '#58AFFF'
						}
					}
				}
			},
			chartData: {
				columns: ['name', 'values'],
				rows: [
					{ name: '过程性', values: 2 },
					{ name: '选修', values: 5 },
					{ name: '社团', values: 13 },
					{ name: '小组学习', values: 20 },
					{ name: '住宿生', values: 28 },
					{ name: '宿舍', values: 2 },
					{ name: '班级', values: 5 },
					{ name: '教师', values: 13 },
					{ name: '我的', values: 20 },
					{ name: '物品', values: 28 }
				]
			},
			tableData: [
				{ knob: '1', one: '班会', four: '活动' },
				{ knob: '2', two: '班会', three: '活动' },
				{ knob: '3', two: '班会' },
				{ knob: '4', five: '活动' },
				{ knob: '5', five: '活动' },
				{ knob: '6', three: '活动' },
				{ knob: '7' },
				{ knob: '8', four: '班会' },
				{ knob: '9', one: '班会' }
			],
			gradeData: [
				{ discipline: '语文', class: '高一（14）班', average: '57.57', ranking: '13' },
				{ discipline: '语文', class: '高一（14）班', average: '57.57', ranking: '13' },
				{ discipline: '语文', class: '高一（14）班', average: '57.57', ranking: '13' }
			],
			ringExtend:{
				legend: {
					show: true,
					icon:'circle'
				},
			},
			settings: {
				radius: [45, 60],
				offsetY: 100,
				labelLine: {
					show: false
				},
				label: {
					show: false
				}
			},
			appColor: ['#A190F7', '#FF7A65', '#14DAAF', '#2D8CF0'],
			chartData2: {
				columns: ['name', 'values'],
				rows: [{ name: '必修', values: 0 }, { name: '负面', values: 0 }]
			},
			chartData3: {
				columns: ['name', 'values'],
				rows: [{ name: '选修', values: 0 }, { name: '负面', values: 0 }]
			},
			chartData4: {
				columns: ['name', 'values'],
				rows: []
			},
			chartData5: {
				columns: ['name', 'values'],
				rows: []
			},
			chartData6: {
				columns: ['name', 'values'],
				rows: []
			},
			chartData: {
				columns: ['name', 'values'],
				rows: [
					{ name: '学生获奖', values: 0 },
					{ name: '学生个人信息', values: 0 },
				]
			},
			chartExtend: {
				legend: {
					show: false
				},
				grid: {
					height: 250
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				series: {
					type: 'bar',
					barWidth: 20,
					itemStyle: {
						normal: {
							color: '#58AFFF'
						}
					}
				}
			},
			weekList:[],
			weeknum:''
		};
	},
	watch: {
		base_info: {
			immediate: true,
			handler(value) {
				this.base_info = value;
			}
		},
		base: {
			immediate: true,
			handler(value) {
				this.base = value;
			}
		},
		recent_exam: {
			immediate: true,
			handler(value) {
				this.recent_exam = value;
			}
		}
	},
	created() {
		
	},
	mounted() {
		console.log(this.base_info)
		setTimeout(()=>{
			this.getDate()
			this.getDetail()
		},500)
	},
	methods: {
		// 获取当前时间
		getDate() {
			this.$api.setting
				.getweekList({
					semester_id: window.localStorage.getItem('semester_id')
				})
				.then(res => {
					if (res.data.code == 1) {
						let data = res.data.data.list;
						let arr = [];
						for (let i = 0;i < data.length;i++) {
							arr.push({
								name: data[i],
								id: i + 1
							});
						}
						this.weekList = arr;
						this.weeknum = res.data.data.now;
						this.getStudentCourse()
					}
				});
		},
		// 获取档案详情
		getDetail(){
			this.$api.student.studentDetail({detail_id: this.$route.query.id}).then(res => {
				if (res.data.code == 1) {
					this.base_info = res.data.data.base_info;
					this.recent_exam = res.data.data.recent_exam;
					this.chartData4.rows = res.data.data.levae;
					this.chartData5.rows = res.data.data.rewards;
					this.chartData6.rows = res.data.data.evaluation;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		more(index){
			this.$emit('more',index)
		},
		// 学生课表
		getStudentCourse(){
			this.$api.student.student_course({
				week: this.weeknum,
				class_id:this.base.class_id
			}).then(res => {
				if (res.data.code == 1) {
					this.attend_day = res.data.data.attend_day;
					let curriculum_time = res.data.data.curriculum_time;
					let list = [];
					if(res.data.data.data){
						list = res.data.data.data
					}
					for(let i=0;i<curriculum_time.length;i++){
						this.$set(curriculum_time[i], 'list', [])
						for(let j=0;j<this.attend_day.length;j++){
							curriculum_time[i].list.push({xq: this.attend_day[j].name, node: curriculum_time[i].node})
							console.log(this.attend_day[j].name)
							let arr = list.filter(item=>item.attend_day == this.attend_day[j].name && item.node == curriculum_time[i].node);
							console.log(arr)
							if(arr.length){
								curriculum_time[i].list[j].discipline_name = arr[0].discipline_name;
								curriculum_time[i].list[j].class_name = arr[0].class_name;
								curriculum_time[i].list[j].teacher_name = arr[0].teacher_name;
							}
						}
					}
					console.log(curriculum_time)
					this.curriculum_time = curriculum_time;
					console.log(this.curriculum_time);
				} else {
					this.$message.error(res.data.msg);
				}
			});
		}
	}
};
</script>

<style lang="scss">
#student{
	.info {
		padding: 15px 30px 0 30px;
		background-color: #ffffff;
		height: 450px;
		box-sizing: border-box;
		.classinfo{
			margin: 15px 0 10px;
			.item{
				position: relative;
				border-radius: 5px;
				overflow: hidden;
				padding: 25px 0 10px 0;
				text-align: center;
				width: 30%;
				box-sizing: border-box;
				.tag{
					position: absolute;
					font-size: 12px;
					color: #fff;
					border-bottom-right-radius: 30px;
					left: 0;
					top: 0;
					padding: 3px 15px 3px 8px;
				}
				.icon{
					
				}
				.name{
					font-size: 12px;
				}
			}
		}

	}
	.class_table {
		background-color: #ffffff;
		.tables {
			height: 400px;
			overflow-y: auto;
			.tb {
				overflow-x: hidden;
				overflow-y: auto;
				-ms-overflow-style: none;
				overflow: '-moz-scrollbars-none';
				scrollbar-width: none;
				div{
					background: #FAFAFA;
					margin: 0.2vw;
					padding: 0.3vw 0;
				}
				.index{
					background: #fff;
				}
				.active {
					background: #E4F1FF;
				}
			}
			.tb::-webkit-scrollbar {
				display: none;
			}
			.th{
				div{
					margin: 0.2vw;
					padding: 0.3vw 0;
				}
			}
		}
	}
	.timecard {
		background-color: #ffffff;
		.timecard-list {
			display: flex;
			flex-wrap: wrap;
			.timecard-item {
				width: 33%;
				height: 80px;
				div {
					span {
						font-size: 1.25rem;
						color: #4998ff;
					}
				}
			}
		}
		.timecard-label{
			background: #F0F3F7;
			width: 60%;
			margin: 30px auto;
			padding: 0 20px;
			.lable-item{
				height: 45px;
				line-height: 45px;
				border-bottom: 1px solid #fff;
				i{
					display: inline-block;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					margin-right: 10px;
				}
				.values{
					color: #999;
				}
			}
			
		}
	}
	.station {
		background-color: #ffffff;
		.station-list {
			background-color: #fafafa;
			height: 158px;
			padding: 15px;
			.station-item {
				margin-bottom: 15px;
				.icon-yuandian1 {
					color: #1890ff;
					margin-right: 0.3125rem;
				}
				.tag {
					background-color: #e8eaec;
					border-radius: 1.25rem;
					margin-left: 0.625rem;
					padding: 0.1875rem 0.9375rem;
				}
			}
		}
	}
	.grade {
		background-color: #ffffff;
		.el-table th {
			padding: 5px 0;
		}
		.score{
			height: 285px;
			overflow-y: auto;
			.score-item{
				div{
					width: 25%;
					text-align: center;
					height: 32px;
					line-height: 32px;
					background: #F6F8FB;
					margin-bottom: 0.4rem;
				}
			}
		}
	}
}	
</style>
