<template>
	<div class="record" style="margin-top: 20px;">
		<div class="contents">
			<div class="u-f-item">
				<div style="margin-right: 20px;">
					<Progress strokeColor="#2D8CF0" :transitionDuration="1000" :radius="50" :strokeWidth="15" value="15">
					  <div style="font-size:26px;color: #2D8CF0;font-weight: 100;">
						  1<span style="font-size: 12px;">分</span>
					  </div>
					  <template v-slot:footer>
							<div class="u-f-justify" style="color: #94A3AB;">必修</div>
					  </template>
					</Progress>
				</div>
				<div style="margin-right: 20px;">
					<Progress strokeColor="#13C2C2" :transitionDuration="1000" :radius="50" :strokeWidth="15" value="15">
					  <div style="font-size:26px;color: #13C2C2;font-weight: 100;">
						  1<span style="font-size: 12px;">分</span>
					  </div>
					  <template v-slot:footer>
							<div class="u-f-justify" style="color: #94A3AB;">选修</div>
					  </template>
					</Progress>
				</div>
				<div style="padding-left: 30px;">
					<div style="font-size: 34px;color: #646F74;margin-bottom: 20px;">0分</div>
					<div style="color: #93999D;">共0分，必修0份，选修0份</div>
				</div>
			</div>
			<div class="font_blod" style="color: #484848;padding: 15px; 0">共7条数据</div>
			<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="id" label="序号"></el-table-column>
				<el-table-column prop="year"  label="学年"></el-table-column>
				<el-table-column prop="term" label="学期"></el-table-column>
				<el-table-column prop="subject" label="学科"></el-table-column>
				<el-table-column prop="code" label="核心素养范围"></el-table-column>
				<el-table-column prop="nature" label="性质">
					<template slot-scope="scope">
						<span class="nature">{{ scope.row.nature }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="credit" label="学分"></el-table-column>
				<el-table-column prop="staff" label="修改人员"></el-table-column>
				<el-table-column label="操作" width="85">
					<template slot-scope="scope">
						<el-button @click="detailsClick(scope.row)" type="text" size="small">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import Progress from "easy-circular-progress";
export default {
	components:{
		Progress
	},
	props: {},
	data() {
		return {
			tableData: [
				{
					id: 1,
					year: '2020学年',
					term: '上学期',
					subject: '语文',
					code: '-',
					nature: '必修',
					credit: '0.00',
					staff: '李老师'
				},
				{
					id: 2,
					year: '2020学年',
					term: '上学期',
					subject: '语文',
					code: '-',
					nature: '必修',
					credit: '0.00',
					staff: '李老师'
				},
				{
					id: 3,
					year: '2020学年',
					term: '上学期',
					subject: '语文',
					code: '-',
					nature: '必修',
					credit: '0.00',
					staff: '李老师'
				},
				{
					id: 4,
					year: '2020学年',
					term: '上学期',
					subject: '语文',
					code: '-',
					nature: '必修',
					credit: '0.00',
					staff: '李老师'
				},
				{
					id: 5,
					year: '2020学年',
					term: '上学期',
					subject: '语文',
					code: '-',
					nature: '必修',
					credit: '0.00',
					staff: '李老师'
				}
			],
			
		};
	},
	created() {},
	mounted() {},
	methods: {
		handleSelectionChange(val) {
			
		}
	}
};
</script>

<style lang="scss">
.record {
	.nav-tab-bar {
		background-color: #ffffff;
		padding: 0.67vw;
		border-bottom: 1px solid #eeeeee;
		.nav-tab-item {
			color: #6e7a87;
			padding: 0 0.78125vw;
			cursor: pointer;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		&div:last-child {
			border-right: none;
		}
		.active {
			color: #63acff;
			position: relative;
			font-weight: bold;
		}
		.active::after {
			position: absolute;
			content: '';
			height: 0.1vw;
			left: 0.78125vw;
			right: 0.78125vw;
			background-color: #63acff;
			bottom: -0.67vw;
		}
	}
	.contents {
		background-color: #ffffff;
		height: 70vh;
		padding: 15px 20px 20px 20px;
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.nature{
			background-color: #E4FBFF;
			color: #007AFF;
			padding: 10px 20px;
			font-size: 12px;
		}
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px!important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
}
.vue-circular-progress .circle__progress {
    stroke-linecap: square ;
}
</style>
