<template>
	<div id="studentDetails">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item to="/student">学生档案</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">{{base_info.realname}}</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == teacherIndex ? 'active' : ''"
						v-for="(item, index) in teacherMeun"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>
				<!-- <div class="u-f-item">
					<el-select v-model="yearval" placeholder="学年" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="changeYear">
						<el-option v-for="item in years" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
					<el-select v-model="subjectval" placeholder="学期" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="changeSemester">
						<el-option v-for="item in subjects" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div> -->
			</div>
			<transition name="el-fade-in-linear">
				<template v-if="teacherIndex==0">
					<student @more="more" :base_info="base_info" :base="base" :rewards="rewards" :levae="levae" :evaluation="evaluation" :recent_exam="recent_exam"></student>
				</template>
				<template v-if="teacherIndex==1">
					<information :base_info="base_info"></information>
				</template>
				<template v-if="teacherIndex==2">
					<classSchedule :base="base"></classSchedule>	
				</template>
				<template v-if="teacherIndex==4">
					<credit></credit>
				</template>
				<template v-if="teacherIndex==3">
					<score :exam_list="exam_list" :num="num"></score>	
				</template>
				<template v-if="teacherIndex==5">
					<certificate></certificate>
				</template>
				<template v-if="teacherIndex==6">
					<log-sheets></log-sheets>
				</template>
				<template v-if="teacherIndex==7">
					<leave-statistics></leave-statistics>
				</template>
				<template v-if="teacherIndex==8">
					<feedback></feedback>
				</template>
				<template v-if="teacherIndex==9">
					<evaluation></evaluation>
				</template>
				<template v-if="teacherIndex==10">
					<rent></rent>
				</template>
				<template v-if="teacherIndex==11">
					<rent></rent>
				</template>
			</transition>
		</index>
	</div>
</template>

<script>
	import index from '@/components/common/index.vue';
	import student from '@/components/student/student.vue';	
	import information from '@/components/student/information.vue';
	import classSchedule from '@/components/student/class-schedule.vue';
	import credit from '@/components/student/credit.vue';
	import score from '@/components/student/score.vue';
	import certificate from '@/components/student/certificate.vue';
	import logSheets from '@/components/student/log-sheets.vue';
	import leaveStatistics from '@/components/student/leave-statistics.vue';
	import Feedback from '@/components/student/feedback.vue';
	import evaluation from '@/components/student/evaluation.vue';
	import Rent from '@/components/student/rent.vue';
	export default {
		components: {
			index,
			student,
			information,
			classSchedule,
			credit,
			score,
			certificate,
			logSheets,
			leaveStatistics,
			Feedback,
			evaluation,
			Rent,
		},
		data() {
			return {
				yearval: '',
				years: [],
				subjects: [],
				subjectval: '',
				teacherMeun: [
					{ name: '档案统计' },
					{ name: '基本信息' },
					{ name: '课表' },
					// { name: '学分' },
					{ name: '成绩' },
					// { name: '材料证明' },
					// { name: '记录' },
					// { name: '请假统计' },
					// { name: '信息反馈' },
					// { name: '学期评语' },
					// { name: '图书借阅' },
					// { name: '消费记录' }
				],
				teacherIndex: 0,
				base_info: {},
				base: {},
				recent_exam: [],
				exam_list: [],
				num:0,
				rewards:[],
				levae:[],
				evaluation:[]
			}
		},
		mounted(){
			this.getDetail();
		},
		methods:{
			// 学年列表
			getYearList(){
				this.$api.setting.getSemesterList().then(res=>{
					if(res.data.code==1){
						this.years = res.data.data;
						this.getSemesterList()
					}
				})
			},
			changeYear(e){
				this.yearval = e;
				this.getSemesterList()
			},
			// 学期列表
			getSemesterList(){
				this.$api.setting.getXueQiList({year_id: this.yearval}).then(res=>{
					if(res.data.code==1){
						this.subjects = res.data.data;
						// this.detailScreening()
					}
				})
			},
			changeSemester(e){
				this.subjectval = e;
				// this.detailScreening()
			},
			detailScreening(){
				let data = {
					campus_id: this.base.campus_id,
					student_id: this.$route.query.id,
					year_id: this.yearval,
					semester_id: this.subjectval,
					grade_id: this.base.grade_id,
				}
				this.$api.student.studentdetailScreening(data).then(res => {
					if (res.data.code == 1) {
						this.yearval = res.data.data.base.year_id;
						this.subjectval = res.data.data.base.semester_id; 
						this.base_info = res.data.data.base_info;
						this.recent_exam = res.data.data.recent_exam;
						this.exam_list = res.data.data.exam_list;
						this.num = res.data.data.exam_list.length;
						this.evaluation = res.data.data.evaluation;
						this.levae = res.data.data.levae;
						this.rewards = res.data.data.rewards;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			// 获取档案详情
			getDetail(){
				this.$api.student.studentDetail({detail_id: this.$route.query.id}).then(res => {
					if (res.data.code == 1) {
						this.yearval = res.data.data.base.year_id;
						this.subjectval = res.data.data.base.semester_id; 
						this.base = res.data.data.base;
						this.base_info = res.data.data.base_info;
						this.recent_exam = res.data.data.recent_exam;
						this.num = res.data.data.exam_list.length
						this.getYearList()
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			twacherTap(index) {
				this.teacherIndex = index;
			},
			more(e){
				this.teacherIndex = e;
			}
		}
	}
</script>

<style lang="scss">
	#studentDetails{
		.nav-tab-bar-box {
			background-color: #ffffff;
			padding: 0.52vw;
			margin-top: 1.04vw;
			.nav-tab-bar {
				.nav-tab-item {
					color: #6e7a87;
					padding: 0 0.78125vw;
					border-right: 1px solid #f3f3f3;
					cursor: pointer;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				div:last-child {
					border-right: none;
				}
				.active {
					font-size: 0.833vw;
					color: #63acff;
					position: relative;
					font-weight: bold;
				}
				.active::after {
					position: absolute;
					content: '';
					height: 2px;
					left: 0.78125vw;
					right: 0.78125vw;
					background-color: #63acff;
					bottom: -0.78125vw;
				}
			}
		}
		
	}
</style>
