<template>
	<div class="logSheet" style="margin-top: 20px;">
		<div class="nav-tab-bar u-f-item">
			<div class="font_14 nav-tab-item" @click="tabTap(index)" :class="index == tabIndex ? 'active' : ''" v-for="(item, index) in tabbars" :key="index">{{ item.name }}</div>
		</div>
		<div class="contents">
			<template v-if="tabIndex == 0">
				<div class="font_blod" style="color: #484848;padding-bottom: 15px;">共7条数据</div>
				<el-table ref="multipleTable" :data="processs" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="id" label="序号"></el-table-column>
					<el-table-column prop="year" label="学年"></el-table-column>
					<el-table-column prop="term" label="学期"></el-table-column>
					<el-table-column prop="grade" label="年级"></el-table-column>
					<el-table-column prop="class" label="班级"></el-table-column>
					<el-table-column prop="student" label="学生"></el-table-column>
					<el-table-column prop="stair" label="一级类别"></el-table-column>
					<el-table-column prop="second" label="二级类别"></el-table-column>
					<el-table-column label="性质">
						<template slot-scope="scope">
							<span class="nature">{{ scope.row.nature }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="rank" label="级别"></el-table-column>
					<el-table-column prop="ranking" label="名次"></el-table-column>
					<el-table-column prop="score" label="分值"></el-table-column>
					<el-table-column prop="explain" label="说明"></el-table-column>
					<el-table-column label="附件">
						<template slot-scope="scope">
							<div class="u-f-item">
								<img src="/static/image/excel.png" v-if="scope.row.type == 'excel'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/pdf2.png" v-if="scope.row.type == 'pdf'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/word.png" v-if="scope.row.type == 'word'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/img.png" v-if="scope.row.type == 'img'" style="width: 20px;margin-right: 5px;" />
								<div>{{ scope.row.accessory }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="logTime" label="记录时间"></el-table-column>
				</el-table>
			</template>
			<template v-if="tabIndex == 1">
				<div class="font_blod" style="color: #484848;padding-bottom: 15px;">共7条数据</div>
				<el-table ref="multipleTable" :data="optional" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange2">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="id" label="序号"></el-table-column>
					<el-table-column prop="year" label="学年"></el-table-column>
					<el-table-column prop="term" label="学期"></el-table-column>
					<el-table-column prop="electives" label="选修"></el-table-column>
					<el-table-column prop="grade" label="年级"></el-table-column>
					<el-table-column prop="class" label="班级"></el-table-column>
					<el-table-column prop="student" label="学生"></el-table-column>
					<el-table-column prop="stair" label="一级类别"></el-table-column>
					<el-table-column prop="second" label="二级类别"></el-table-column>
					<el-table-column label="性质">
						<template slot-scope="scope1">
							<span class="nature">{{ scope1.row.nature }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="rank" label="级别"></el-table-column>
					<el-table-column prop="ranking" label="名次"></el-table-column>
					<el-table-column prop="score" label="分值"></el-table-column>
					<el-table-column prop="explain" label="说明"></el-table-column>
					<el-table-column label="附件">
						<template slot-scope="scope1">
							<div class="u-f-item">
								<img src="/static/image/excel.png" v-if="scope1.row.type == 'excel'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/pdf2.png" v-if="scope1.row.type == 'pdf'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/word.png" v-if="scope1.row.type == 'word'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/img.png" v-if="scope1.row.type == 'img'" style="width: 20px;margin-right: 5px;" />
								<div>{{ scope1.row.accessory }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="logTime" label="记录时间"></el-table-column>
				</el-table>
			</template>
			<template v-if="tabIndex == 2">
				<div class="font_blod" style="color: #484848;padding-bottom: 15px;">共7条数据</div>
				<el-table ref="multipleTable" :data="tableData3" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange3">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="id" label="序号"></el-table-column>
					<el-table-column prop="year" label="学年"></el-table-column>
					<el-table-column prop="term" label="学期"></el-table-column>
					<el-table-column prop="electives" label="社团"></el-table-column>
					<el-table-column prop="grade" label="年级"></el-table-column>
					<el-table-column prop="class" label="班级"></el-table-column>
					<el-table-column prop="student" label="学生"></el-table-column>
					<el-table-column prop="stair" label="一级类别"></el-table-column>
					<el-table-column prop="second" label="二级类别"></el-table-column>
					<el-table-column label="性质">
						<template slot-scope="scope2">
							<span class="nature">{{ scope2.row.nature }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="rank" label="级别"></el-table-column>
					<el-table-column prop="ranking" label="名次"></el-table-column>
					<el-table-column prop="score" label="分值"></el-table-column>
					<el-table-column prop="explain" label="说明"></el-table-column>
					<el-table-column label="附件">
						<template slot-scope="scope2">
							<div class="u-f-item">
								<img src="/static/image/excel.png" v-if="scope2.row.type == 'excel'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/pdf2.png" v-if="scope2.row.type == 'pdf'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/word.png" v-if="scope2.row.type == 'word'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/img.png" v-if="scope2.row.type == 'img'" style="width: 20px;margin-right: 5px;" />
								<div>{{ scope2.row.accessory }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="logTime" label="记录时间"></el-table-column>
				</el-table>
			</template>
			<template v-if="tabIndex == 3">
				<div class="font_blod" style="color: #484848;padding-bottom: 15px;">共7条数据</div>
				<el-table ref="multipleTable" :data="groupList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange2">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="id" label="序号"></el-table-column>
					<el-table-column prop="year" label="学年"></el-table-column>
					<el-table-column prop="term" label="学期"></el-table-column>
					<el-table-column prop="class" label="班级"></el-table-column>
					<el-table-column prop="course" label="课程"></el-table-column>
					<el-table-column prop="group" label="小组"></el-table-column>
					<el-table-column prop="student" label="学生"></el-table-column>
					<el-table-column prop="stair" label="一级类别"></el-table-column>
					<el-table-column prop="second" label="二级类别"></el-table-column>
					<el-table-column label="性质">
						<template slot-scope="sco">
							<span class="nature">{{ sco.row.nature }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="score" label="分值"></el-table-column>
					<el-table-column prop="explain" label="说明"></el-table-column>
					<el-table-column label="附件">
						<template slot-scope="sco">
							<div class="u-f-item">
								<img src="/static/image/excel.png" v-if="sco.row.type == 'excel'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/pdf2.png" v-if="sco.row.type == 'pdf'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/word.png" v-if="sco.row.type == 'word'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/img.png" v-if="sco.row.type == 'img'" style="width: 20px;margin-right: 5px;" />
								<div>{{ sco.row.accessory }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="logTime" label="记录时间"></el-table-column>
				</el-table>
			</template>
			<template v-if="tabIndex == 4">
				<div class="font_blod" style="color: #484848;padding-bottom: 15px;">共7条数据</div>
				<el-table ref="multipleTable" :data="dormitoryList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange2">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="id" label="序号"></el-table-column>
					<el-table-column prop="year" label="学年"></el-table-column>
					<el-table-column prop="term" label="学期"></el-table-column>
					<el-table-column prop="housenum" label="房间号"></el-table-column>
					<el-table-column prop="class" label="班级"></el-table-column>
					<el-table-column prop="student" label="学生"></el-table-column>
					<el-table-column
						prop="stair"
						:filters="[{ text: '家', value: '家' }, { text: '公司', value: '公司' }]"
						:filter-method="filterTag"
						filter-placement="bottom-end"
						label="一级类别"
					></el-table-column>
					<el-table-column
						prop="second"
						label="二级类别"
						:filters="[{ text: '家', value: '家' }, { text: '公司', value: '公司' }]"
						:filter-method="filterTag"
						filter-placement="bottom-end"
					></el-table-column>
					<el-table-column label="性质">
						<template slot-scope="sco">
							<span class="nature">{{ sco.row.nature }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="score" label="分值"></el-table-column>
					<el-table-column prop="explain" label="说明"></el-table-column>
					<el-table-column label="附件">
						<template slot-scope="sco">
							<div class="u-f-item">
								<img src="/static/image/excel.png" v-if="sco.row.type == 'excel'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/pdf2.png" v-if="sco.row.type == 'pdf'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/word.png" v-if="sco.row.type == 'word'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/img.png" v-if="sco.row.type == 'img'" style="width: 20px;margin-right: 5px;" />
								<div>{{ sco.row.accessory }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="logTime" label="记录时间"></el-table-column>
				</el-table>
			</template>
			<template v-if="tabIndex == 5">
				<div class="font_blod" style="color: #484848;padding-bottom: 15px;">共7条数据</div>
				<el-table ref="multipleTable" :data="dormitoryList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange2">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="id" label="序号"></el-table-column>
					<el-table-column prop="year" label="学年"></el-table-column>
					<el-table-column prop="term" label="学期"></el-table-column>
					<el-table-column prop="housenum" label="房间号"></el-table-column>
					<el-table-column
						prop="stair"
						:filters="[{ text: '家', value: '家' }, { text: '公司', value: '公司' }]"
						:filter-method="filterTag"
						filter-placement="bottom-end"
						label="一级类别"
					></el-table-column>
					<el-table-column
						prop="second"
						label="二级类别"
						:filters="[{ text: '家', value: '家' }, { text: '公司', value: '公司' }]"
						:filter-method="filterTag"
						filter-placement="bottom-end"
					></el-table-column>
					<el-table-column label="性质">
						<template slot-scope="sco">
							<span class="nature">{{ sco.row.nature }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="score" label="分值"></el-table-column>
					<el-table-column prop="explain" label="说明"></el-table-column>
					<el-table-column label="附件">
						<template slot-scope="sco">
							<div class="u-f-item">
								<img src="/static/image/excel.png" v-if="sco.row.type == 'excel'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/pdf2.png" v-if="sco.row.type == 'pdf'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/word.png" v-if="sco.row.type == 'word'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/img.png" v-if="sco.row.type == 'img'" style="width: 20px;margin-right: 5px;" />
								<div>{{ sco.row.accessory }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="logTime" label="记录时间"></el-table-column>
				</el-table>
			</template>
			
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			tabbars: [
				{ name: '过程性' },
				{ name: '选修' },
				{ name: '社团' },
				{ name: '小组学习' },
				{ name: '住宿生' },
				{ name: '宿舍' }
			],
			tabIndex: 0,
			maintabIndex: 0,
			multipleSelection: [],
			multipleSelection2: [],
			multipleSelection3: [],
			processs: [
				{
					id: 1,
					year: '2020学年',
					term: '上学期',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 2,
					year: '2020学年',
					term: '上学期',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 3,
					year: '2020学年',
					term: '上学期',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 4,
					year: '2020学年',
					term: '上学期',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 5,
					year: '2020学年',
					term: '上学期',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				}
			],
			optional: [
				{
					id: 1,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 2,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 3,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 4,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 5,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				}
			],
			tableData3: [
				{
					id: 1,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 2,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 3,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 4,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 5,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					grade: '高一',
					class: '高一（14）班',
					student: '张三',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					rank: '一级',
					ranking: '1',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				}
			],
			groupList: [
				{
					id: 1,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					class: '高一（14）班',
					course: '语文',
					group: '学习小组',
					student: '张三|李四|王五',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 2,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					class: '高一（14）班',
					course: '语文',
					group: '学习小组',
					student: '张三|李四|王五',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 3,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					class: '高一（14）班',
					course: '语文',
					group: '学习小组',
					student: '张三|李四|王五',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 4,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					class: '高一（14）班',
					course: '语文',
					group: '学习小组',
					student: '张三|李四|王五',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				},
				{
					id: 5,
					year: '2020学年',
					term: '上学期',
					electives: '古典音乐作曲',
					class: '高一（14）班',
					course: '语文',
					group: '学习小组',
					student: '张三|李四|王五',
					stair: '自习纪律',
					second: '与学习无关',
					nature: '负面',
					score: '12分',
					explain: '无',
					accessory: '图片19',
					logTime: '2020-12-10 16:30',
					type: 'img'
				}
			],
			dormitoryList: []
		};
	},
	created() {},
	mounted() {},
	methods: {
		maintabTap(index) {
			this.maintabIndex = index;
		},
		tabTap(index) {
			this.tabIndex = index;
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		handleSelectionChange2(val) {
			this.multipleSelection2 = val;
		},
		handleSelectionChange3(val) {
			this.multipleSelection3 = val;
		},
		filterTag(value, row) {
			return row.tag === value;
		}
	}
};
</script>

<style lang="scss">
.logSheet {
	.nav-tab-bar {
		background-color: #ffffff;
		padding: 0.67vw;
		border-bottom: 1px solid #eeeeee;
		.nav-tab-item {
			color: #6e7a87;
			padding: 0 0.78125vw;
			cursor: pointer;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		&div:last-child {
			border-right: none;
		}
		.active {
			color: #63acff;
			position: relative;
			font-weight: bold;
		}
		.active::after {
			position: absolute;
			content: '';
			height: 0.1vw;
			left: 0.78125vw;
			right: 0.78125vw;
			background-color: #63acff;
			bottom: -0.67vw;
		}
	}
	.contents {
		background-color: #ffffff;
		height: 70vh;
		padding: 20px;
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.nature {
			background-color: #ffe1e1;
			color: #da4444;
			padding: 4px 15px;
		}
		.main-tab-bars {
			border: 1px solid #dadfe0;
			.main-tab-item {
				height: 30px;
				line-height: 30px;
				width: 100px;
				text-align: center;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.active {
				color: #67adf4;
				background-color: #e1f3ff;
				border: 1px solid #67adf4;
			}
		}
	}
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px;
	}
}
</style>
