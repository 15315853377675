<template>
	<div style="padding-top: 20px;">
		<div class="contents">
			<div class="table">
				<div style="height: 500px;" class="u-f-justify u-f-column">
					<img src="../../assets/image/nothing.png" style="width: 120px;">
					<div style="color: #A4A4A4;">暂无数据</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			
		};
	},
	created() {},
	mounted() {},
	methods: {
		
	}
};
</script>

<style lang="scss">
.contents {
	background-color: #ffffff;
	padding: 20px;
}
</style>
