<template>
	<div style="padding-top: 20px;" id="score">
		<div class="contents">
			<!-- <div class="u-f-item head">
				<div style="margin-right: 30px">显示和导出设置</div>
				<el-checkbox-group v-model="checkList">
					<el-checkbox label="全选" @change="selectALL"></el-checkbox>
					<el-checkbox label="分值"></el-checkbox>
					<el-checkbox label="等级分"></el-checkbox>
					<el-checkbox label="Z分"></el-checkbox>
					<el-checkbox label="△Z"></el-checkbox>
					<el-checkbox label="班级排名"></el-checkbox>
					<el-checkbox label="年级排名"></el-checkbox>
					<el-checkbox label="等级"></el-checkbox>
					<el-checkbox label="临界"></el-checkbox>
					<el-checkbox label="班级进退步"></el-checkbox>
					<el-checkbox label="年级进退步"></el-checkbox>
					<el-checkbox label="等级分差异"></el-checkbox>
				</el-checkbox-group>
			</div> -->
			<div class="table">
				<div class="font_blod" style="color: #484848;padding-bottom: 15px;">共{{num}}条数据</div>
				<el-table ref="multipleTable" :data="exam_list" tooltip-effect="dark" style="width: 100%;height: 70vh;" @selection-change="handleSelectionChange">
					<!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
					<el-table-column prop="year_name" label="学年" align="center"></el-table-column>
					<el-table-column prop="semester_name" label="学期" align="center"></el-table-column>
					<el-table-column prop="name" label="考试" align="center"></el-table-column>
					<el-table-column label="学科" align="center" v-if="exam_list.length">
						<el-table-column align="center" v-for="(title, index) in exam_list[0].discipline_exam" :key="index" :label="title.discipline_name">
							<template slot-scope="scope">
								<span>{{scope.row.discipline_exam[index].score}}</span>
							</template>
						</el-table-column>
						<!-- <el-table-column  prop="discipline_name" label="姓名" width="120" v-for="(r,i) in discipline_exam"></el-table-column> -->
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		exam_list: {
			type: Array,
			default: []
		},
		num:{
			type:Number,
			default:0
		}
	},
	data() {
		return {
			checkList: ['全选', '分值', '等级分', 'Z分', '△Z', '班级排名', '年级排名', '等级', '临界', '班级进退步', '年级进退步', '等级分差异'],
			multipleSelection: []
		};
	},
	watch: {
		exam_list: {
			immediate: true,
			handler(value) {
				this.exam_list = value;
			}
		}
	},
	mounted() {},
	methods: {
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		selectALL(e){
			console.log(e)
			if(e){
				this.checkList = ['全选', '分值', '等级分', 'Z分', '△Z', '班级排名', '年级排名', '等级', '临界', '班级进退步', '年级进退步', '等级分差异']
			}else{
				this.checkList = []
			}
		},
	}
};
</script>

<style lang="scss">
#score{
	.contents {
		background-color: #ffffff;
		.head {
			padding: 20px;
		}
		.table{
			padding: 20px;
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 15px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
		}
	}
}

</style>
